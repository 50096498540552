import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useGlobalJsonForm } from "gatsby-tinacms-json"

const Header: React.FC = () => {
  const data = useStaticQuery(graphql`
    query navQuery {
      dataJson(fileRelativePath: { eq: "/src/data/nav.json" }) {
        nav {
          label
          link
        }

        # TinaCMS fields
        rawJson
        fileRelativePath
      }
    }
  `)

  const menu = data.dataJson.nav
  const [menuData] = useGlobalJsonForm(data.dataJson, FormOptions)

  return (
    <div
      id="st_k"
      data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky"
    >
      <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
        <div className="uk-navbar-left">
          <a href="#">
            <span data-uk-icon="icon: menu; ratio: 1.25"></span>
          </a>
          <div
            className="uk-navbar-dropdown"
            data-uk-drop="boundary: !nav; boundary-align: true; pos: top-left;"
          >
            <ul className="uk-nav uk-navbar-dropdown-nav">
              {menu.map((nav) => (
                <li key={nav.label + nav.link}>
                  {nav.link.match(/http/) ? (
                    <a href={nav.link} target="_blank">
                      {nav.label}
                    </a>
                  ) : (
                    <Link to={nav.link}>{nav.label}</Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="uk-navbar-center">
          <Link to="/" id="logo">
            <img alt="MaxRewards Logo" src="/logo-white.svg" />
          </Link>
        </div>

        <div className="uk-navbar-right">
          <a
            className="uk-hidden@s"
            href="https://maxrewards.app.link/MD3ztgJuw4"
          >
            <span data-uk-icon="icon: download; ratio: 1.25"></span>
          </a>
          <a className="uk-visible@s" href="#">
            <span data-uk-icon="icon: download; ratio: 1.25"></span>
          </a>
          <div
            className="uk-navbar-dropdown"
            data-uk-drop="boundary: !nav; boundary-align: true; pos: top-right;"
          >
            <ul className="uk-nav uk-navbar-dropdown-nav">
              <li>
                <a href="https://apps.apple.com/us/app/id1435710443">
                  Download on App Store
                </a>
              </li>
              <li>
                <a href="https://play.google.com/store/apps/details?id=com.maxrewards&hl=en_US">
                  Download on Play Store
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

const FormOptions = {
  label: "Header Navigation",
  fields: [
    {
      label: "Header Navigation",
      name: "rawJson.nav",
      description: "navigation items",
      component: "group-list",
      fields: [
        {
          name: "label",
          label: "Label",
          component: "text",
        },
        {
          name: "link",
          label: "Link",
          component: "text",
        },
      ],
      itemProps: (item: any) => ({ key: item.key, label: item.label }),
      defaultItem: {
        key: Math.random().toString(36).substr(2, 9),
        label: "New Item",
        link: "/",
      },
    },
  ],
}

export default Header
