import React from "react"
import { Link } from "gatsby"

interface FooterProps {
  useDlLinks?: boolean
}

const Footer: React.FC<FooterProps> = ({ useDlLinks = false }) => {
  return (
    <footer id="footer">
      <div className="uk-container uk-container-small">
        <div className="uk-child-width-1-2 uk-child-width-1-3@s" data-uk-grid>
          <div>
            <h2 className="uk-h4">Product</h2>

            <ul className="uk-list">
              <li>
                <Link to="/">Overview</Link>
              </li>

              <li>
                <Link to="/features">Features</Link>
              </li>

              <li>
                <a
                  href="https://feedback.maxrewards.co/changelog"
                  target="_blank"
                >
                  Changelog
                </a>
              </li>

              <li>
                <Link to="/security">Security</Link>
              </li>

              <li>
                <a href="https://help.maxrewards.co" target="_blank">
                  Help Center
                </a>
              </li>

              <li>
                <a
                  href="https://help.maxrewards.co/en/collections/1076156-frequently-asked-questions"
                  target="_blank"
                >
                  FAQ
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="uk-h4">Company</h2>

            <ul className="uk-list">
              <li>
                <Link to="/about">About</Link>
              </li>

              <li>
                <a
                  href="http://help.maxrewards.co/en/articles/3705925/"
                  target="_blank"
                >
                  How We Make Money
                </a>
              </li>

              <li>
                <Link to="/contact">Contact</Link>
              </li>

              <li>
                <a
                  href="https://angel.co/company/maxrewards/jobs"
                  target="_blank"
                >
                  Jobs
                </a>
              </li>

              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>

              <li>
                <Link to="/terms-of-use/">Terms of Use</Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="uk-h4">Follow Us</h2>
            {/* {!useDlLinks ?

							:
							<div style={{ marginBottom: 30 }}>
								<div style={{ marginBottom: 15 }}>
									<a href="https://apps.apple.com/us/app/maxrewards-earn-more-rewards/id1435710443"><img alt='Download on the App Store' className="height-xxsmall" src="/download-maxrewards-app-store.svg" /></a>
								</div>

								<div>
									<a href="https://play.google.com/store/apps/details?id=com.maxrewards&hl=en_US&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"><img alt='Get it on Google Play' className="height-xxsmall" src="/download-maxrewards-play-store.svg" /></a>
								</div>
							</div>
						} */}

            <ul className="uk-grid-small" data-uk-grid="">
              <li>
                <a
                  href="http://instagram.com/getmaxrewards/"
                  target="_blank"
                  title="facebook"
                >
                  <span data-uk-icon="icon: instagram; ratio: 1.5"></span>
                </a>
              </li>

              <li>
                <a
                  href="https://www.facebook.com/getmaxrewards/"
                  target="_blank"
                  title="facebook"
                >
                  <span data-uk-icon="icon: facebook; ratio: 1.5"></span>
                </a>
              </li>

              <li>
                <a
                  href="https://twitter.com/maxrewards/"
                  target="_blank"
                  title="twitter"
                >
                  <span data-uk-icon="icon: twitter; ratio: 1.5"></span>
                </a>
              </li>

              <li>
                <a
                  href="https://www.linkedin.com/company/maxrewards/"
                  target="_blank"
                  title="linkedin"
                >
                  <span data-uk-icon="icon: linkedin; ratio: 1.5"></span>
                </a>
              </li>
            </ul>
          </div>
          <div className="uk-width-1-1">
            &copy; Copyright {new Date().getFullYear()} MaxRewards, Inc.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
