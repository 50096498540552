import React from "react"

require("../../styles/styles.scss")
import Header from "../header/header"
import Footer from "../footer/footer"

interface LayoutProps {
  footerDl?: boolean
}

const HIRE_STRING = `
##################################################
##################################################
##################################################
##################################################
#%%%%%%%#%%%%%%%#%%%%%%%#%%%%%%%#%%%%%%%#%%%%%%%#%
%%%%%%%%%%%%%%%%.%%%%%%%%%%%%%%%%% %%%%%%%%%%%%%%%
%%%%%%%%%%%%%%%    %%%%%%%%%%%%%    %%%%%%%%%%%%%%
%%%%%%%%%%%%%%                       %%%%%%%%%%%%%
%%%%%%%%%%%%%*..                   ..*%%%%%%%%%%%%
%%%%%%%%%%%%%.....               .....%%%%%%%%%%%%
%%%%%%%%%%%%........           ........%%%%%%%%%%%
%%%%%%%%%%%...........       ...........%%%%%%%%%%
%%%%%%%%%%%*............   ............/%%%%%%%%%%
%%%%%%%%%%%%%%*,,,,,.,,.. ,..,,.....*%%%%%%%%%%%%%
%%%%%%%%%%%%%%%%%,,,,,       ,,,.,%%%%%%%%%%%%%%%%
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
%&%%%&%&%%%&%&%&%&%&&&%&%&%&%&%&%&%&%&%&%&%&%&%&&&
&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

Hi there! Interested in working for MaxRewards?
Check out our job board at https://boards.greenhouse.io/maxrewards
`

class Layout extends React.PureComponent<LayoutProps, {}> {
  UIkit: any
  Icons: any

  constructor(props) {
    super(props)
  }

  componentDidMount = () => {
    try {
      this.UIkit = require("uikit/dist/js/uikit.min")
      this.Icons = require("uikit/dist/js/uikit-icons.min")
      this.UIkit.use(this.Icons)
      const script = document.createElement("script")
      script.innerText = `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_edulgnp');rdt('track', 'PageVisit');`
      const swag = document.querySelector("body")
      swag?.appendChild(script)
      console.log(HIRE_STRING)
    } catch (e) {}
  }

  render() {
    const { children, footerDl } = this.props

    return (
      <>
        <main className="homepage">
          <Header />
          {children}
        </main>
        <Footer useDlLinks={footerDl} />
      </>
    )
  }
}

export default Layout
